import analytics from '@capturi/analytics'
import {
  PreviewAccessRequestModel,
  PreviewAccessResponse,
  useCurrentUser,
} from '@capturi/core'
import { getAcl, getPermissionPreset } from '@capturi/filters'
import {
  PersonsWithAccess,
  PreviewAccessWarningText,
  ShareAndEditOptions,
  ValidationErrorText,
  usePreviewAccess,
} from '@capturi/sharing'
import { Button } from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  VStack,
  useToast,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import noop from 'lodash/noop'
import React from 'react'

import { DashboardAdminEvent, logEvent } from '../events'
import {
  previewAccessRequest,
  useCreateDashboardFolder,
  useUpdateDashboardFolder,
} from '../hooks/useDashboardFolders'
import useDeleteFolderConfirmationDialog from '../hooks/useDeleteFolderConfirmationDialog'
import { CreateDashboardFolderRequestModel, DashboardFolder } from '../types'

function getPreviewAccessWarningText(
  previewAccessResponse: PreviewAccessResponse | undefined,
): string | undefined {
  switch (previewAccessResponse) {
    case 'NoAccess':
      return t`*You will no longer be able to view the folder when you apply`
    case 'Editable':
      return undefined
    case 'Viewable':
      return t`*You will no longer be able to edit the folder when you apply because you set your own permission to “viewer” `
  }
}

export type CreateUpdateDashboardFolderDialogProps = {
  folder?: DashboardFolder
  onFolderCreated?: (folder: DashboardFolder) => void
  onFolderDeleted?: () => void
} & BaseModalComponentProps<{
  folder?: DashboardFolder
}>

type CreateDashboardFolderModel = CreateDashboardFolderRequestModel & {
  hasWarnings?: boolean
}

const TitleInput: React.FC<{
  inputRef?: React.RefObject<HTMLInputElement>
  value: string | undefined
  onChange: (value: string) => void
}> = ({ value, onChange, inputRef }) => {
  return (
    <FormControl isRequired>
      <FormLabel htmlFor="folder-title">
        <Trans>Name</Trans>
      </FormLabel>
      <Input
        id="folder-title"
        ref={inputRef}
        placeholder={t`Name`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onChange(e.target.value)
        }
        value={value}
      />
    </FormControl>
  )
}

const DescriptionTextarea: React.FC<{
  value: string | undefined
  onChange: (value: string) => void
}> = ({ value, onChange }) => {
  return (
    <FormControl>
      <FormLabel htmlFor="folder-description">
        <Trans>Description</Trans>
      </FormLabel>
      <Textarea
        id="folder-description"
        placeholder={t`Add a description that explains what the folder should be used for ...`}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>): void =>
          onChange(e.target.value)
        }
        value={value}
      />
    </FormControl>
  )
}

export const CreateUpdateDashboardFolderDialog: React.FC<
  CreateUpdateDashboardFolderDialogProps
> = ({ onClose, folder: folderProps, onFolderCreated, onFolderDeleted }) => {
  const titleRef = React.useRef<HTMLInputElement>(null)
  const currentUser = useCurrentUser()
  const {
    mutate: updateFolder,
    isPending: isUpdateLoading,
    error: updateFolderError,
  } = useUpdateDashboardFolder()
  const { mutate: createFolder } = useCreateDashboardFolder()

  const toast = useToast()

  // State
  const initialPermissionPreset = folderProps?.permissionPreset ?? 'Private'
  const initialPermissionPresetUserUid =
    folderProps?.permissionPresetUserUid ??
    initialPermissionPreset === 'Private'
      ? currentUser.id
      : null

  const [state, _setState] = React.useState<CreateDashboardFolderModel>(() => ({
    title: folderProps?.title ?? '',
    description: folderProps?.description ?? '',
    permissionPreset: initialPermissionPreset,
    permissionPresetUserUid: initialPermissionPresetUserUid,
    acl: folderProps?.acl ?? [],
  }))

  const localizedEntityName = t`dashboard folder`
  const showsACL = state.permissionPreset === 'Custom'

  const setState = React.useCallback(
    (partialState: Partial<CreateDashboardFolderModel>): void => {
      _setState((state) => ({
        ...state,
        ...partialState,
        acl: getAcl(partialState, state, currentUser.id),
        permissionPresetUserUid: getPermissionPreset(
          partialState,
          state,
          currentUser.id,
        ),
      }))
    },
    [currentUser.id],
  )

  const previewAccessRequestModel: PreviewAccessRequestModel = {
    uid: folderProps?.uid,
    permissionPreset: state.permissionPreset,
    permissionPresetUserUid: state.permissionPresetUserUid,
    acl: state.acl,
    folderUid: null,
  }
  const { isPreviewAccessLoading, previewAccessResponse, validationError } =
    usePreviewAccess({
      previewAccessRequestModel,
      createPreviewAccessRequest: previewAccessRequest,
    })

  if (
    previewAccessResponse &&
    state.hasWarnings !==
      ['NoAccess', 'Viewable'].includes(previewAccessResponse)
  ) {
    setState({
      hasWarnings: ['NoAccess', 'Viewable'].includes(previewAccessResponse),
    })
  }

  const openDeleteFolderDialog = useDeleteFolderConfirmationDialog({
    folderUid: folderProps?.uid,
    onFolderDeleted: () => {
      onClose()
      onFolderDeleted?.()
    },
  })

  const modalHeader = folderProps
    ? t`Folder settings`
    : t`Add new dashboard folder`
  const submitButtonText = folderProps ? t`Save Folder` : t`Apply`

  const handleCreateOrUpdateFolder = (): void => {
    if (folderProps) {
      updateFolder(
        { uid: folderProps.uid, folder: state },
        {
          onSuccess: () => {
            toast({
              title: t`Folder saved`,
              status: 'success',
            })
            onClose()
          },
          onError: (error) => {
            toast({
              title: t`An error occurred`,
              description: error.message,
              status: 'error',
            })
          },
        },
      )
    } else {
      createFolder(state, {
        onSuccess: (folder) => {
          logEvent(DashboardAdminEvent.DashboardFolderCreated, {
            permissionPreset: state.permissionPreset,
          })

          toast({
            title: t`Folder saved`,
            status: 'success',
          })
          onClose()
          onFolderCreated?.(folder)
        },
        onError: (error) => {
          toast({
            title: t`An error occurred`,
            description: error.message,
            status: 'error',
          })
        },
      })
    }
  }

  return (
    <Modal
      isOpen
      onClose={isUpdateLoading ? noop : onClose}
      initialFocusRef={titleRef}
      size={state.permissionPreset === 'Custom' ? '4xl' : 'lg'}
    >
      <ModalOverlay>
        <ModalContent>
          <ModalCloseButton isDisabled={isUpdateLoading} />
          <ModalHeader>{modalHeader}</ModalHeader>
          <ModalBody>
            <Grid
              templateColumns={{
                base: '1fr',
                md: showsACL ? '1fr 1px 1fr' : '1fr',
              }}
              gap={4}
            >
              <Stack
                borderRight={{
                  base: 'none',
                  md: showsACL
                    ? '1px solid var(--chakra-colors-gray-200)'
                    : 'none',
                }}
                pr={{
                  base: 0,
                  md: showsACL ? 8 : 0,
                }}
                pb={{ base: 2, md: 0 }}
                spacing={4}
              >
                <TitleInput
                  inputRef={titleRef}
                  value={state.title}
                  onChange={(value) => setState({ title: value })}
                />
                <DescriptionTextarea
                  value={state.description}
                  onChange={(value) => setState({ description: value })}
                />
                <ShareAndEditOptions
                  permissionPreset={state.permissionPreset}
                  onChange={(permissionPreset) =>
                    setState({ permissionPreset })
                  }
                  localizedEntityName={localizedEntityName}
                />
              </Stack>
              <Divider />
              {showsACL && (
                <Flex maxH={{ base: '660px', md: '430px' }}>
                  <PersonsWithAccess
                    acl={state.acl || []}
                    onChange={(acl) => setState({ acl: acl })}
                  />
                </Flex>
              )}
            </Grid>
          </ModalBody>
          <ModalFooter>
            <VStack alignItems="start" w="100%">
              <Text fontSize="sm" color="warning">
                {updateFolderError?.message}
                <PreviewAccessWarningText
                  warningText={getPreviewAccessWarningText(
                    previewAccessResponse,
                  )}
                />
                <ValidationErrorText validationError={validationError} />
              </Text>
              <Flex justifyContent="space-between" w="100%">
                {folderProps?.uid ? (
                  <Button
                    colorScheme="red"
                    type="button"
                    onClick={openDeleteFolderDialog}
                    isDisabled={isUpdateLoading}
                  >
                    <Trans>Delete</Trans>
                  </Button>
                ) : (
                  <Box />
                )}

                <Stack direction="row" spacing={4}>
                  <Button
                    type="button"
                    onClick={onClose}
                    isDisabled={isUpdateLoading}
                  >
                    <Trans>Cancel</Trans>
                  </Button>
                  <Button
                    primary
                    onClick={() => {
                      handleCreateOrUpdateFolder()
                      analytics.event('folderPermissions', { state })
                    }}
                    isDisabled={
                      validationError !== undefined || isPreviewAccessLoading
                    }
                    isLoading={isUpdateLoading}
                  >
                    {submitButtonText}
                  </Button>
                </Stack>
              </Flex>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
