import { PeriodDefinition } from '@capturi/filters'
import { Button, useToast } from '@capturi/ui-components'
import {
  ButtonGroup,
  Flex,
  IconButton,
  Link,
  Stack,
  Tooltip,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import qs from 'query-string'
import React from 'react'
import { MdContentCopy, MdLaunch } from 'react-icons/md'
import { useCopyToClipboard } from 'react-use'

import { DashboardAdminEvent, logEvent } from '../../events'
import { AccessKey } from '../../types'

const createPublicUrl = (key: string, periodName?: string): string => {
  return qs.stringifyUrl({
    url: 'https://dashboard.capturi.ai',
    query: {
      accessKey: key,
      period: periodName,
    },
  })
}

type Props = {
  item: AccessKey
  periodDef: PeriodDefinition
}

const AccessKeyItem: React.FC<Props> = ({ item, periodDef }) => {
  const { key } = item
  const toast = useToast()
  const [, copy] = useCopyToClipboard()

  const onCopyURL = (key: string): void => {
    logEvent(DashboardAdminEvent.CopySharedLink)
    const url = createPublicUrl(key, periodDef.name)
    copy(url)
    toast({
      status: 'info',
      title: t`Link copied to clipboard`,
    })
  }

  const copyLinkText = t`Copy link`
  const openLinkText = t`Open link`
  return (
    <Flex direction="column">
      <Stack direction="row" spacing={2}>
        <ButtonGroup spacing={2} size="sm">
          <Tooltip label={copyLinkText}>
            <Button
              aria-label={copyLinkText}
              leftIcon={<MdContentCopy />}
              onClick={() => onCopyURL(key)}
              primary
            >
              <Trans>Copy link</Trans>
            </Button>
          </Tooltip>
          <Tooltip label={openLinkText}>
            <IconButton
              as={Link}
              aria-label={openLinkText}
              icon={<MdLaunch />}
              href={createPublicUrl(key, periodDef.name)}
              target="_blank"
              onClick={() => {
                logEvent(DashboardAdminEvent.OpenSharedLink)
              }}
            />
          </Tooltip>
        </ButtonGroup>
      </Stack>
    </Flex>
  )
}

export default AccessKeyItem
